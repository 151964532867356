<template>
  <div class="min-h-screen bg-blue-100 flex items-center justify-center ">
    <div class="inner bg-white max-w-sm p-8 shadow-lg text-center rounded-2xl">
      <h1 class="text-4xl leading-snug   text-gray-700  capitalize font-bold">page not <br> found </h1>
      <p class="my-6">
        The page you're looking for could not be found. It might have been removed, replaced or it doesn't exist.
      </p>
      <a href="/" class="btn btn-primary btn-block">Go Home </a>
    </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>